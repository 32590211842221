import * as React from "react"
import Home from "../views/Home"
import { usePages } from "../hooks/usePages"
import { useItems } from "../hooks/useItems"
import { useServices } from "../hooks/useServices"
import { useProcess } from "../hooks/useProcess"

const IndexPage = () => {
  const info = usePages("Principal Spanish")
  const buttonText = useItems("Button Spanish").content[0].text
  const services = useServices("es")
  const process = useProcess("es")
  return (
    <Home
      info={info}
      buttonText={buttonText}
      services={services}
      process={process}
    />
  )
}

export default IndexPage
